@import 'vendor/components/foundation/scss/foundation.scss';
@include foundation-everything;

@import '_lightbox.scss';
@import 'vendor/font-awesome/font-awesome.scss';

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,300,600);

// Colors
$background: #ECF0F1;

$white: #FFFFFF;

$black: #000000;
$offblack: #3B444F;

$red: #B10000;

$wwblue: #0154A4;
$fadedblue: #1D70C0;
$lightblue: #5DAEFF;
$mediumblue: #0056A5;
$blue: #122143;
$darkblue: #424A54;

$lightgray: #E4E9EF;
$gray: #C4C4C4;
$mediumgray: #434955;
$darkgray: #2B3036;

$button-lightblue: #5FB0FF;
$button-blue: #0154A4;
$button-red: #ED4545;

$highlight: #DAEDFE;
$highlight-border: #A6D1F4;

$error: #FADEDD;
$error-border: #EB9B9C;

$form-field: #FAFAFA;
$form-field-text: #424A54;
$form-field-border: #DBDFE0;
$form-field-active: #FFFFFF;
$form-field-border-active: #A6D0F6;


.login-message {
	margin: 15px;
  text-align: center;
  font-style: italic;
}

.banner {
	background: $white;
	margin-bottom: 20px;
	text-align: center;
	h5 {
		padding: 15px;
		a {
			text-decoration: underline;
		}
	}
}

/** TEMP GOOGLE MAP STYLE **/
.google-map {
	width: 100%;
	height: 400px;
	iframe {
		width: 100%;
		height: 100%;
	}
}


body {
	font-family: 'Source Sans Pro', 'Helvetica', 'Arial';
	background: $background;
}

h1 {
	color: $darkblue;
	font-weight: 600;
	font-size: 42px;
}
h2 {
	color: $lightblue;
}
h3 {
	color: $darkblue;
}
h4 {
	color: $darkblue;
}
h5 {
	color: $mediumblue;
}

.button, .button-mobile {
	text-align: center;
	float: left;
	background: $button-blue;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 18px;
	font-weight: 400;
	&.remove {
		float: right;
		background: $button-red;
		&:hover {
			background: lighten($button-red, 5%);
		}
	}
	&.spacer {
		margin-top: 20px;
	}
}

.error-messages {
	list-style: none;
	background: $error;
	padding: 15px;
	margin: 0 0 15px 0;
	border: 1px solid $error-border;
	.error {
		padding: 2px 0;
		color: $red;
	}
}

.pagination {
	li {
		background: $white;
		font-size: 18px;
		margin: 0 3px;
		border: 1px solid $blue;
		&.active {
			padding: 3px 10px;
			background: $wwblue;
			color: $white;
		}
		&.disabled {
			display: none;
		}
		a, span {
			font-size: 18px;
		}
	}
}

table {
	font-weight: 600;
	width: 100%;
	tbody tr {
		border: 1px solid $gray;
		&:hover {
			border: 1px solid $highlight-border;
			background: $highlight;
		}
	}
	td, th {
		padding: 10px;
	}
	th {
		text-transform: uppercase;
		font-weight: 900;
		font-size: 22px;
		color: $darkblue;
		background: $background;
	}
	td {
		width: 40%;
		color: $darkblue;
		a {
			color: $darkblue;
			&:hover {
				color: $lightblue;
			}
		}
	}
	.link {
		text-transform: uppercase;
		text-align: right;
		width: 20%;
		a {
			color: $lightblue;
		}
	}
	tr.spacer {
		height: 10px;
		border: 0;
	}
}

.inline-input {
	position: relative;
	.label {
		background: none;
		display: block;
		font-size: 20px;
		position: absolute;
		margin-left: 5px;
		top: 12px;
		color: $offblack;
		transition: 0.2s;
		&.focus {
			color: $lightblue;
			font-size: 14px;
			top: 2px;
		}
	}

	.input{
		background: $form-field;
		border: 2px solid $form-field-border;
		color: $form-field-text;
		padding-left: 10px;
		height: 55px;
		padding-top: 25px;
		&:not(select, textarea) {
		}
		&:focus:not(select) {
			background: $white;
			border: 2px solid $form-field-border-active;
		}
	}

	select.input {
		padding-top: 5px;
		font-size: 20px;
		outline: none;
	}
	.arrow-dropdown {
		fill: $form-field-text;
		position: absolute;
		height: 20px;
		width: 20px;
		top: 18px;
		right: 15px;
	}
	textarea.input {
		padding-top: 5px;
		height: 150px;
	}

	&.state, &.zipcode {
		float: left;
	}
	&.state {
		width: 40%;
		padding-right: 2%;
	}
	&.zipcode {
		width: 60%;
	}


	@include breakpoint(medium) {
		&.city {
			float: left;
		}
		&.city {
			width: 60%;
			padding-right: 2%;
		}
		&.state {
			width: 15%;
			padding-right: 2%;
		}
		&.zipcode {
			width: 25%;
		}
	}
}

.navigation-slide {
	position: fixed;
	left: -250px;
	width: 250px;
	height: 100%;
	background: $white;
	transition: 0.5s;

	&.open {
		left: 0;
	}

	@include breakpoint(large) {
		&.init {
			left: 0;
		}
	}

	.items {
		list-style: none;
		margin: 0;
		.item {
			border-bottom: 1px solid $lightgray;
			&.logo {
				background: $wwblue;
				height: 100px;
				padding: 20px;
				svg {
					width: 200px;
					fill: $white;
				}
			}
			.page-link {
				display: block;
				border-left: 5px solid $white;
				padding: 15px 10px;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 18px;
				color: $darkblue;
				svg {
					position: relative;
					top: 5px;
					left: 0;
					height: 25px;
					width: 25px;
					margin-right: 10px;
				}
				&:hover, &.active {
					border-left: 5px solid $lightblue;
					color: $lightblue;
					svg {
						fill: $lightblue;
					}
				}
				#star {
					stroke: $black;
					stroke-width: 1px;
					fill: none;
				}
				&:hover, &.active {
					#star{
						stroke: $lightblue;
					}
				}
			}
		}
	}

	.sub-items {
		list-style: none;
		margin: 0;
		.sub-item {
			.category-link, .metricwise-link {
				display: block;
				padding: 10px 10px 10px 40px;
				font-size: 16px;
				font-weight: 400;
				color: $darkblue;
				&:hover {
					color: $lightblue;
				}
			}
		}
	}
}


.container {
	position: relative;
	left: 0;
	margin-left: 0;
	transition: 0.5s;

	&.nav-open {
		left: 250px;

		.nav-toggle:before {
			content: "";
			top: 15px;
			left: -2px;
			position: absolute;
			border-width: 5px 5px 5px 0;
			border-style: solid;
			border-color: transparent $lightblue ;
			display: block;
			width: 0;
		}
	}
	@include breakpoint(large) {
		&.init {
			margin-left: 250px;

			.nav-toggle:before {
				content: "";
				top: 15px;
				left: -2px;
				position: absolute;
				border-width: 5px 5px 5px 0;
				border-style: solid;
				border-color: transparent $lightblue ;
				display: block;
				width: 0;
			}
		}

		&.nav-open {
			left: 0;
			margin-left: 250px;
		}
	}

	.top-bar {
		height: 60px;
		background: $darkgray;
		.items {
			list-style: none;
			margin: 0;
			&.left {
				background: $darkgray;
				float: left;
			}
			&.right {
				background: $darkgray;
				float: right;
			}
			.item {
				display: inline-block;
				position: relative;
				.icon {
					display: block;
					padding: 10px;
					svg {
						fill: $white;
						height: 24px;
						width: 24px;
						&:hover, &.active {
							fill: $lightblue;
							cursor: pointer;
						}
					}
				}
				&.drawer-open:before {
					content: "";
					top: 45px;
					left: 12px;
					position: absolute;
					border-width: 0 10px 10px;
					border-style: solid;
					border-color: lighten($mediumgray, 15%) transparent;
					display: block;
					width: 0;
				}
				&.resource {
					.icon {
						display: block;
						margin-top: 2px;
						margin-right: 5px;
						padding: 5px 20px;
						background: $mediumgray;
						border-radius: 25px;
						color: $white;
						font-weight: 400;
						font-size: 16px;
						svg {
							height: 12px;
							fill: $lightblue;
							color: $lightblue;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
	.content {
		margin: 0 auto;
		padding: 25px 25px;
		max-width: 900px;
		background: $background;
	}

	.top-drawer {
		background: lighten($mediumgray, 15%);
		overflow: hidden;
		.inline-input .input{
			background: $white;
			border: 1px solid $lightblue;
		}
		.button {
			fill: $white;
			margin-top: 15px;
			padding: 15px;
			background: $lightblue;
			height: 55px;
			width: 55px;
			&:hover {
				fill: $fadedblue;
			}
		}

		.button-mobile {
			margin-left: 4%;
			color: $white;
			fill: $white;
			padding: 15px;
			background: $lightblue;
			height: 55px;
			width: 90%;
			font-size: 22px;
			&:hover {
				fill: $fadedblue;
			}
			svg {
				transform: rotate(-90deg);
				fill: $white;
				stroke: $white;
				height: 16px;
			}
		}
		.user {
			.inline-input {
				margin: 2%;
				margin-top: 15px;
				float: left;
				width: 95%;
				@include breakpoint(medium) {
					width: 40%;
				}
			}
			.status {
				width: fill;
				text-align: center;
				font-size: 18px;
				color: $white;
				margin: 15px;
				float: left;
				padding: 15px;
				a {
					color: $white;
					&:hover {
						color: $lightblue;
					}
				}
				@include breakpoint(medium) {
					width: auto;
					text-align: left;
				}
			}
			.button {
				display: none;
				@include breakpoint(medium) {
					margin-top: 15px;
					display: block;
					&.logout {
						margin-top: 25px;
						width: 150px;
						height: 40px;
						padding: 10px;
						float: right;
					}
					svg {
						height: 14px;
						stroke: $white;
						transform: rotate(-90deg);
					}
					&:hover {
						stroke: $fadedblue;
						fill: $fadedblue;
					}
				}
			}
			.button-mobile {
				&.logout {
					margin-top: 30px;
					padding: 10px;
				}
				@include breakpoint(medium) {
					display: none;
				}
			}
		}
		.search {
			.inline-input {
				margin: 2%;
				margin-top: 15px;
				float: left;
				width: 75%;
				@include breakpoint(medium) {
					width: 85%;
				}
			}

		}
		.user, .search {
			width: 100%;
			height: 0;
			overflow: hidden;
			transition: 0.2s;
		}

		.user.open {
			height: 265px;
		}

		.search.open {
			height: 90px;
		}

		@include breakpoint(medium) {
			.user.open {
				height: 90px;
			}
		}
	}
}

.page-dashboard, .page-categories {
	.block {
		margin-bottom: 30px;
		overflow: hidden;
		border: 1px solid $gray;

		&.announcement {
			border: solid 2px $highlight-border;
			background: $highlight;
			.title {
				padding: 15px 20px 0;
				h2 {
					text-transform: uppercase;
					color: $lightblue;
					font-size: 14px;
					font-weight: 600;
				}
			}
			.description {
				.content {
					background: $highlight;
					font-weight: 200;
					padding: 0 20px 15px;
					margin: 0;
				}
			}
		}

		&.callout {
			border: 1px solid $gray;
			width: 100%;
			text-align: center;
			padding: 10px;
			.icon-heading {
				padding: 5px;
				overflow: hidden;
				width: 100%;
				text-align: center;
				@include breakpoint(medium) {
					width: auto;
					text-align: left;
					float: left;
				}
			}
			.icon {
				// float: left;
				display: inline-block;
				svg {
					position: relative;
					top: 3px;
					height: 30px;
					width: 30px;
					fill: $lightblue;
				}
			}
			h2 {
				// float: left;
				display: inline-block;
				margin: 0;
				padding: 0 15px;
				font-size: 26px;
				text-transform: uppercase;
				font-weight: 600;
			}
			.button{
				margin: 5px;
				color: $lightblue;
				width: auto;
				padding: 8px 50px;
				@include breakpoint(medium) {
					float: right;
				}
				svg {
					margin-left: 5px;
					height: 15px;
					width: 15px;
					fill: $lightblue;
					stroke: $lightblue;
					transform: rotate(-90deg);
				}
			}
		}

		&.resources, &.jobs {
			.title {
				position: relative;
				background: $wwblue;
				float: left;
				width: 100%;
				padding: 10px;
				.line1, .line2 {
					text-transform: uppercase;
					font-weight: 600;
					font-size: 28px;
					margin: 5px;
					float: left;
					width: 100%;
					line-height: 20px;
				}
				.line1 {
					margin-top: 20px;
				}
				.icon {
					height: 50px;
					width: 50px;
					position: absolute;
					top: 10px;
					right: 10px;
					display: block;
					margin: 5px;
					svg {
						fill: $fadedblue;
					}
				}
				h2 {
					color: $white;
				}
				a {
					color: $white;
				}


				@include breakpoint(medium) {
					width: 40%;
					height: 300px;
					text-align: left;
					.line1, .line2 {
						margin-top: 15px;
						display: block;
						font-size: 42px;
						line-height: 24px;
					}
					.line1 {
						margin-top: 80px;
					}
					.line2 {
						clear: left;
					}
				}
			}
			.listings {
				float: left;
				width: 100%;
				background: $white;
				@include breakpoint(medium) {
					width: 60%;
					height: 300px;
				}
				a {
					padding: 10px 5px;
					display: block;
					width: 100%;
					color: $black;
					overflow: hidden;
					.name, .category, .view {
						display: block;
						float: left;
						clear: left;
						line-height: 20px;
						@include breakpoint(medium) {
							clear: none;
						}
					}

					.name {
						max-width: 250px;
						white-space: nowrap;
					  overflow: hidden;
					  text-overflow: ellipsis;
						font-size: 18px;
						font-weight: 700;
					}
					.category {
						padding: 0;
						font-size: 16px;
						font-weight: 500;
						color: $gray;
						@include breakpoint(medium) {
							margin-left: 10px;
						}
					}
					.view {
						text-transform: uppercase;
						font-size: 15px;
						font-weight: 700;
						color: $lightblue;
						@include breakpoint(medium) {
							float: right;
						}
					}
					&:hover {
						.name, .category, .view {
							color: $lightblue;
						}
					}
				}
				.resources, .jobs {
					list-style: none;
					margin: 0;
					padding: 15px;
					.resource, .job {
						width: 100%;
						float: left;
					}
				}
			}
		}

		.button {
			margin: 15px 0;
			padding: 8px 0;
			background: transparent;
			border: 2px solid $lightblue;
			float: none;
			width: 47%;
			&.add {
				margin-left: 5px;
				clear: left;
				svg {
					fill: $lightblue;
					height: 14px;
				}
			}
			&.view {
				margin-right: 5px;
				svg {
					transform: rotate(-90deg);
					margin-left: 5px;
					fill: $lightblue;
					stroke: $lightblue;
					height: 14px;
				}
				float: right;
			}
			@include breakpoint(medium) {
				float: left;
				margin-top: 60px;
			}
		}
	}

	.category.listing {
		margin-bottom: 20px;
		overflow: hidden;

		@include breakpoint(medium) {
			width: 48%;
			float: left;
			&:nth-of-type(2n+1) {
				float: right;
			}
			&:nth-of-type(2n) {
				clear: left;
			}
		}

		.title {
			overflow: hidden;
			width: 100%;
			h2 {
				margin: 0;
				color: $darkblue;
				font-size: 32px;
				font-weight: 600;
			}

		}
		.listings {
			.resources {
				list-style: none;
				margin: 0;
				.resource {
					margin: 8px 0;
					a {
						display: block;
						padding: 5px 15px;
						border: 1px solid $lightgray;
						background: $white;
						color: $darkblue;
						font-weight: 400;
						font-size: 18px;
						overflow: hidden;
						.title {
							width: auto;
							float: left;
						}
						.date {
							color: $gray;
							float: right;
						}
						&:hover {
							color: $lightblue;
							background: $highlight;
							border: 1px solid $highlight-border;
						}
					}

				}
			}
		}

		.button {
			margin-top: 10px;
			padding: 5px 30px;
			background: $lightblue;
			color: $white;
			svg {
				transform: rotate(-90deg);
				fill: $lightblue;
				stroke: $lightblue;
				margin-left: 5px;
				height: 14px;
			}
		}
	}
}

.page-resource, .page-jobs {
	.button-small{
		float: left;
		margin-right: 20px;
		color: $darkblue;
		font-size: 14px;
		svg {
			fill: $darkblue;
			height: 12px;
			width: 12px;
		}
		&:hover {
			text-decoration: underline;
			color: $lightblue;
		}
		&.photo-remove {
			padding: 5px;
			&:hover {
				color: $red;
				svg {
					fill: $red;
				}
			}
		}
	}
	h1 {
		display: block;
		clear: left;
		float: left;
		margin-bottom: 0;
	}
	h3 {
		font-weight: 600;
	}
	p {
		margin-bottom: 5px;
	}
	.content {
		width: 100%;
		float: left;
		clear: left;
		margin: 10px 0;
		padding: 20px;
		background: $white;
	}
	.author {
		float: left;
		clear: left;
		font-style: italic;
		font-size: 14px;
		color: $mediumgray;
	}
	.text {
		float: left;
		clear: left;
		width: 100%;
	}
	.lower-buttons {
		overflow: hidden;
		width: 100%;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight: 600;
		.edit {
			float: left;
		}
		.upload {
			margin-right: 0;
			float: right;
			&:hover {
				svg {
					fill: $lightblue;
				}
			}
		}
	}

	.document {
		float: left;
		margin: 5px 15px 5px 0;
		color: $darkblue;
		background: $white;
		padding: 5px 10px;
		border: 1px solid $gray;
		min-width: 200px;
		.file svg {
			margin-right: 5px;
			height: 15px;
			width: 15px;
		}
		.download svg {
			display: none;
			height: 15px;
			width: 15px;
			float: right;
			margin-top: 3px;
			fill: $lightblue;
		}
		&:hover {
			color: $lightblue;
			background: $highlight;
			border: 1px solid $lightblue;
			.file svg {
				fill: $lightblue;
			}
			.download svg {
				display: block;
			}
		}
	}

	.comments {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		float: left;
		width: 100%;
		.comment {
			background: $white;
			overflow: hidden;
			margin: 0 0 30px 0;
			.content, .author {
				padding: 0 10px 10px;
			}
		}
	}

	.comment-form {
		margin-top: 30px;
		float: left;
		width: 100%;
	}

	.photos {
		.button-small {
			float: right;
			margin-right: 0;
		}

		.photo {
			float: left;
			margin-right: 15px;
			margin-bottom: 20px;
			padding: 15px;

			@include breakpoint(medium) {
				width: 30%;
				margin-bottom: 0;
				&:nth-of-type(3n+1) {
					clear: left;
				}
			}
			.thumbnail {
				margin: 5px 0;
				&:hover {
					cursor: pointer;
				}
			}
			.caption {
				float: left;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 700;
				color: $gray;
				max-width: 250px;
				white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;
			}
		}
	}

}

.page-search {
	.results {
		list-style: none;
		margin: 20px 0 0;
		.result {

		}
		.link {
			margin-bottom: 0;
			a {
				font-size: 18px;
				font-weight: 600;
			}
		}
		.snippet {
			padding: 0 15px;
			margin-top: 0;
		}
	}
}

.page-location-map {

	#map {
		float: left;
		height: 500px;
		width: 100%;
	}
	.use-gps {
		margin: 15px 0 30px;
	}
	h4 {
		clear: left;
		font-weight: 500;
	}

	.jobs {
		display: none;
	}
}

.page-gallery {
	.gallery-links {
		list-style: none;
		margin: 20px 0 0 0;
		overflow: hidden;
		background: $white;
		padding: 15px;
		.gallery {
			display: block;
			padding: 5px 10px;
			.name {
				font-weight: 700;
				font-size: 18px;
				color: $darkblue;
			}
			.count {
				margin-left: 10px;
				font-size: 14px;
				font-weight: 600;
				color: $gray;
			}
			.view {
				float: right;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700;
				color: $gray;
			}
			&:hover {
				.name, .count, .view {
					color: $lightblue;
				}
			}
		}
	}

	.gallery {
		h2 {
			margin-top: 40px;
			padding: 5px 15px;
			font-size: 32px;
			font-weight: 600;
			color: $white;
			background: $mediumgray;
		}
		.photo, .video {
			float: left;
			margin: 30px 30px 0 0;
			.thumbnail {
				margin: 0;
				max-width: 600px;
				max-height: 180px;
			}
			.wistia_embed {
				height: 175px;
				width: 310px;
			}
			.caption {
				padding: 5px;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700;
				color: $gray;
				margin: 0;
				max-width: 250px;
				white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;
			}
			.remove {
				float: right;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 700;
				color: $gray;
				&:hover {
					color: $red;
				}
			}
		}
	}
}
